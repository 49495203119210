import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { CheckBox } from 'grommet';
import { Box, Button, Heading, Modal } from 'components/Wrapped';
import { Loading } from 'components/Loading';

const IntroVideoModal = ({ t }) => {
  const [loadingBuffer, setLoadingBuffer] = useState(true);
  const [toggleModal, setToggleModal] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const toggle = () => setToggleModal(!toggleModal);

  useEffect(() => {
    setTimeout(() => {
      setLoadingBuffer(false);
    }, 800);
  }, []);

  const confirm = () => {
    if (isChecked) {
      localStorage.setItem('isIntroVideoWatched', true);
      toggle();
    }
  };

  return (
    <Modal
      show={toggleModal}
      keepOpen={true}
      width="large"
      pad="xxsmall"
      margin={{ vertical: 'medium' }}
    >
      <Box style={{ textAlign: 'center' }}>
        <Heading level={1}>{t('modals.introVideo.title')}</Heading>
        <Heading level={4} margin={{ top: 'small' }}>
          {t('modals.introVideo.subtitle')}
        </Heading>
      </Box>
      <Box>
        {loadingBuffer ? (
          <Loading />
        ) : (
          <video controls>
            <source
              key="video"
              src="https://hover-trusts-public.s3.eu-west-1.amazonaws.com/onboarding-video.mp4"
              type="video/mp4"
            />
            {/* TODO - subtitles not yet provided */}
            {/* <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src="/assets/subtitles/introVideo.vtt"
          /> */}
          </video>
        )}
      </Box>
      <Box>
        <CheckBox
          checked={isChecked}
          label={t('modals.introVideo.checkboxLabel')}
          onChange={event => setIsChecked(event.target.checked)}
        />
      </Box>
      <Box direction="row" justify="center" align="center">
        <Button color="primary" onClick={confirm} disabled={!isChecked}>
          {t('buttons.continue')}
        </Button>
      </Box>
    </Modal>
  );
};

export default withNamespaces()(IntroVideoModal);
