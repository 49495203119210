import * as React from 'react';

const Padlock = ({ fill = '#4fb291', ...props }) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 489.6 489.6"
    style={{
      enableBackground: 'new 0 0 489.6 489.6',
    }}
    xmlSpace="preserve"
    fill={fill}
    {...props}
  >
    <g>
      <g>
        <g>
          <path
            d="M179.75,127.9c0-35.8,29.2-64.9,64.9-64.9s64.9,29.2,64.9,64.9v64.9h63v-64.9c0-70.8-57.6-127.9-127.9-127.9 s-127.9,57.2-127.9,127.9v64.9h63V127.9z"
            fill={fill}
          />
          <path
            d="M389.35,213.9H99.95c-19.1,0-34.6,15.6-34.6,34.6V455c0,19.1,15.6,34.6,34.6,34.6h289.7c19.1,0,34.6-15.6,34.6-34.6 V248.5C424.35,229.4,408.75,213.9,389.35,213.9z M273.05,413.4h-56.8l11.3-60.3c-9.7-5.8-16.7-16.7-16.7-28.8 c0-18.7,15.2-33.8,33.8-33.8s33.8,15.2,33.8,33.8c0,12.4-6.6,22.9-16.7,28.8L273.05,413.4z"
            fill={fill}
          />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
export default Padlock;
