const darkTheme = {
  global: {
    colors: {
      brand: '#4fb291',
      control: '#4fb291',
      red: '#db2327',
      green: '#26bf22',
      white: '#fff',
      whiteHover: 'rgba(255, 255, 255, 0.5)',
      bidColor: '#26bf22',
      askColor: '#4fb291',
      background: '#0C151D',
      primary: '#4fb291',
      primaryHover: '#4fb291',
      loadingColor: '#4fb291',
      focus: '#4fb291',
      'background-1': '#0C151D',
      'background-2': '#243f56',
      'background-3': '#182A3A',
      'background-4': '#40474f',
      'background-5': '#62666f',
      'border-1': 'rgba(255,255,255,0.33)',
      'handleColor': '#fff',
      defaultTextColor: '#f8f8f8',
      defaultTextColorDarken: '#d9d9d9',
      tableBackground: '#1c3143',
      tableHeadBackground: 'background-4',
      selectBackground: '#1c3143',
      errorPageBackground: '#fff',
      footerBackground: '#182A3A',
      navbarBackground: '#243f56',
      skinnyBarBackground: '#0C151D',
      cookieConsentBackground: 'accent-4',
      draftStatusBackground: '#e0fded',
      trendingNoticeBackground: '#243f56'
    },
    font: {
      size: '12px',
      lineHeight: '1.25',
      family: '"Open Sans"',
    },
  },
  button: {
    primary: {
      color: '#f8f8f8',
    },
    border: {
      radius: '8px',
    },
    color: '#f8f8f8'
  },
  tab: {
    active: {
      color: 'brand',
    },
    color: 'text',
    border: {
      side: 'bottom',
      size: 'small',
      color: {
        dark: 'text',
        light: 'text',
      },
      active: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
      hover: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
    },
  },
  chartingTheme: 'Dark',
  modalBackgroundColor: 'grey-lighter',
  modalConfirmationBackgroundColor: 'grey-darker',
  formButtonColor: 'primary',
};

const lightTheme = {
  global: {
    colors: {
      brand: '#143b68',
      red: '#db2327',
      green: '#26bf22',
      white: '#fff',
      whiteHover: 'rgba(255, 255, 255, 0.5)',
      bidColor: '#26bf22',
      askColor: '#143b68',
      background: '#F2F2F2',
      primary: '#143b68',
      primaryHover: '#143b68',
      loadingColor: '#143b68',
      'background-1': '#F2F2F2',
      'background-2': '#fbfcfe',
      'background-3': '#ffffff',
      'background-4': '#F2F2F2',
      'background-5': '#F2F2F2',
      defaultTextColor: '#444444',
      defaultTextColorDarken: '#333333',
      'border-1': 'rgba(0,0,0,0.33)',
      handleColor: '#f7f7f7',
      tableBackground: '#fbfcfe',
      selectBackground: '#fbfcfe',
      errorPageBackground: '#fff',
      navbarBackground: '#F2F2F2',
      skinnyBarBackground: '#fbfcfe',
      footerBackground: '#F2F2F2',
      cookieConsentBackground: 'accent-4',
      focus: '#143b68',
      draftStatusBackground: '#e0e8fd',
      trendingNoticeBackground: '#ffffff'
    },
    font: {
      size: '12px',
      lineHeight: '1.25',
      family: '"Open Sans"',
    },
  },
  button: {
    border: {
      radius: '8px',
    },
    color: '#f8f8f8'
  },
  tab: {
    active: {
      color: 'brand',
    },
    color: 'text',
    border: {
      side: 'bottom',
      size: 'small',
      color: {
        dark: 'text',
        light: 'text',
      },
      active: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
      hover: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
    },
  },
  chartingTheme: 'Light',
  modalBackgroundColor: 'grey-lighter',
  modalConfirmationBackgroundColor: 'grey-darker',
  formButtonColor: 'primary',
};

const themes = [
  {
    themeName: 'Dark',
    theme: darkTheme,
  },
  {
    themeName: 'Light',
    theme: lightTheme,
  },
];

window.themes = themes;

export default themes;
