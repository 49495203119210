import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Box } from 'grommet';
import { v4 as uuidv4 } from 'uuid';

import { hoverTrustCoApi } from 'api';
import { nestedTranslate } from 'utils/strings';
import { Button } from 'components/Wrapped';
import { Link } from 'react-router-dom';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import UploadBox from './UploadBox';
import { getS3UrlExtension } from '../../../../utils/strings.js';
import Axios from 'axios';
import { updateOnboarding } from 'redux/actions/onboarding';
import { onboardingStatus } from 'utils/enums/onboardingStatus';
import { triggerToast } from 'redux/actions/ui';
import Loader from 'react-loader-spinner';
import { TermsAndConditions } from '../TermsAndConditions';
import { TextInput } from 'components/Wrapped';
import styled, { css } from 'styled-components';
import { colorStyle } from 'grommet-styles';
import s from '../Documents.module.scss';

const origin =
  process.env.REACT_APP_ORIGIN ||
  'https://develop-exchange-hovertrusts.netlify.app/';

const TextField = styled(TextInput)`
  ${props =>
    props.hasFocus &&
    css`
      box-shadow: none;
      border-color: unset;
    `}

  ${props =>
    props.hasAddonStart &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}

    ${props =>
    props.hasAddonEnd &&
    css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `}


  ${props =>
    props.hasErrors && colorStyle('border-color', 'askColor', props.theme)}
`;

//  technical debt => uploading functionality was done to upload SEVERAL files at once
//  but the business requirements have changed and only ONE file is needed
//  so uploading functionality could be refactored

const Upload = ({ t: translate, isTrustAgree }) => {
  const t = nestedTranslate(translate, 'account.onboarding.documents');
  const dispatch = useDispatch();

  const {
    trustcoId,
    onboarding,
    firstName,
    middleName,
    lastName,
  } = useSelector(({ user }) => user.profile);

  const { are_terms_conditions_signed: areTermsConditionsSigned } = onboarding;
  const { are_trust_costs_signed: areTrustCostsSigned } = onboarding;

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);

  const [error, setError] = useState('');
  const [signError, setSignError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [signature, setSignature] = useState('');

  const areTermsConditionsAndCostsSigned =
    areTermsConditionsSigned && areTrustCostsSigned;

  const onClickBox = async (file, index, existingFile) => {
    const key = `documents/${uuidv4()}.pdf`;
    const putUrl = await hoverTrustCoApi.authenticatedCall({
      url: `documents/presignedPutUrl/params?key=${key}`,
      method: 'GET',
    });

    setFilesToUpload(prevState => {
      const newState = [...prevState];
      newState[index] = { putUrl, file };
      return newState;
    });
    if (existingFile) {
      setFilesToRemove(prevState => {
        const newState = [...prevState];
        newState[index] = existingFile;
        return newState;
      });
    }
  };

  const downloadTrustAgreement = async () => {
    const fullName = middleName
      ? `${firstName} ${middleName} ${lastName}`
      : `${firstName} ${lastName}`;
    if (fullName !== signature) {
      setSignError(t('error.fullNameError'));
      setTimeout(() => {
        setSignError('');
      }, 4000);
      return;
    }

    const trustAgreementDoc = await hoverTrustCoApi.authenticatedCall({
      //url: 'trustagreement/download',
      url: '/users/downloadTrustAgreement',
      method: 'POST',
      responseType: 'blob',
    })

    const file = new Blob([trustAgreementDoc], { type: 'application/pdf' });
    const name = `${uuidv4()}.pdf`;
    const key = `documents/${name}`;
    const putUrl = await hoverTrustCoApi.authenticatedCall({
      url: `documents/presignedPutUrl/params?key=${key}`,
      method: 'GET',
    });
    setFilesToUpload(prevState => {
      const newState = [...prevState];
      newState[0] = { putUrl, file, fileName: name };
      return newState;
    });

    setIsSign(true);
    /* const fileURL = window.URL.createObjectURL(file);
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    const fileName = 'HOVER_Trust_Agreement.pdf'
    fileLink.setAttribute('download', fileName);
    fileLink.setAttribute('target', '_blank');
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove(); */
  }

  const deleteRejectedFiles = async () => {
    const sanitizedFilesToRemove = filesToRemove.filter(file => file);
    let allFilesRemoved = true;
    for (const file of sanitizedFilesToRemove) {
      try {
        const res = await hoverTrustCoApi.authenticatedCall({
          url: `documents/${file.id}`,
          method: 'DELETE',
        });
        if (!res) {
          allFilesRemoved = false;
          break;
        }
      } catch (error) {
        allFilesRemoved = false;
        break;
      }
    }
    return allFilesRemoved;
  };

  const uploadFiles = async () => {
    if (!areTermsConditionsAndCostsSigned) {
      setError(t('error.signCheckboxes'));
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }
    if (!filesToUpload.length || !isTrustAgree || signature.length === 0 || !isSign) {
      setError(t('error.missingSignature'));
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }

    if (filesToRemove.length) {
      const allFilesRemoved = await deleteRejectedFiles();
      if (!allFilesRemoved) return throwError();
    }

    try {
      setIsUploading(true);
      let errorUploading = false;
      for (const { putUrl, file, fileName } of filesToUpload) {
        try {
          const { status } = await Axios.put(putUrl, file, {
            headers: {
              'Access-Control-Allow-Origin': `${origin}`,
              'Content-Type': 'application/pdf',
              'x-amz-server-side-encryption': 'AES256',
            },
          });

          if (status !== 200) {
            errorUploading = true;
            break;
          }
        } catch (error) {
          errorUploading = true;
          break;
        }

        const s3UrlExtension = getS3UrlExtension(putUrl);
        try {
          await hoverTrustCoApi.authenticatedCall({
            url: `documents`,
            method: 'POST',
            data: {
              userId: trustcoId,
              fileName,
              s3UrlExtension,
            },
          });
        } catch (error) {
          errorUploading = true;
          break;
        }
      }

      if (errorUploading) {
        setIsUploading(false);
        throwError();
        return;
      }

      dispatch(
        updateOnboarding({
          body: {
            areDocumentsSigned: true,
            areDocumentsRejected: false,
            subtype: onboardingStatus.DOCUMENTS,
            data: 'Signing of documents',
          },
        }),
      );
      dispatch(triggerToast(t('success.thanks'), 'success'));
      setIsUploading(false);
    } catch (error) {
      throwError();
    }
  };

  const throwError = () => {
    dispatch(
      triggerToast(
        'There has been an error, please try again later',
        'error',
        6000,
      ),
    );
    setTimeout(() => {
      window.location.reload();
    }, 6000);
  };

  return (
    <>
      {/* <UploadBox
        existingFile={documents[0]}
        handleFile={(file, existingFile) => onClickBox(file, 0, existingFile)}
      >
        <WrappedParagraph>{t('steps.clickToUpload')}</WrappedParagraph>
      </UploadBox> */}

      <Box direction="row" className={s.boxLink}>
        <Box margin={{ right: 'small' }}>
          <WrappedParagraph base>
            {t('steps.inputInfo')}
          </WrappedParagraph>
        </Box>
        <Box width="100" style={{ flexGrow: 1 }} >
          <TextField
            name="test"
            type="text"
            placeholder={t('inputs.name')}
            // className={styles.modifiedTextField}
            value={signature}
            margin={{ bottom: '0px' }}
            onChange={(e) => {
              setSignature(e.currentTarget.value)
            }}
            hasErrors={false}
          />
        </Box>
        <Button
          onClick={downloadTrustAgreement}
          margin={{ horizontal: 'xsmall' }}
          color="primary"
          disabled={isSign}
        >
          {t('buttons.sign')}
        </Button>
      </Box>
      {signError && (
        <WrappedParagraph base align="center" color="red">
          {signError}
        </WrappedParagraph>
      )}

      <TermsAndConditions />

      {/* Submit buttons */}
      <Box direction="row" justify="center" align="center" pad="small">
        <Link to="/account">
          <Button
            margin={{ horizontal: 'xsmall' }}
            color="primary"
            primary={false}
          >
            {t('buttons.cancel')}
          </Button>
        </Link>
        <Button
          onClick={uploadFiles}
          margin={{ horizontal: 'xsmall' }}
          color="primary"
        >
          {!isUploading ? (
            t('buttons.submit')
          ) : (
            <Loader color="white" height="20" width="20" type="Oval" />
          )}
        </Button>
      </Box>
      {error && (
        <WrappedParagraph base textAlign="center" color="red">
          {error}
        </WrappedParagraph>
      )}
    </>
  );
};

export default withNamespaces()(Upload);
