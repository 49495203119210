import * as React from "react";
const ArrowDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48px"
    viewBox="0 -960 960 960"
    width="48px"
    {...props}
  >
    <path d="M480-369.46q-6 0-10.81-2-4.81-2-9.42-6.62L269.85-568q-6.7-6.69-6.58-16.12.12-9.42 7.19-16.49 7.08-7.08 16.31-7.08 9.23 0 16.31 7.08L480-423.08l177.54-177.53q6.69-6.7 15.81-6.58 9.11.11 16.19 7.19 7.07 7.08 7.07 16.31 0 9.23-7.07 16.3L500.23-378.08q-4.61 4.62-9.42 6.62t-10.81 2Z" />
  </svg>
);
export default ArrowDownIcon;
