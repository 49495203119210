import React, { useContext } from 'react';
import { ThemeContext } from 'grommet';
import lightLogo from '../../assets/img/logo.png';
import darkLogo from '../../assets/img/logo-dark.png';

const Logo = ({ style, className }) => {
  const theme = useContext(ThemeContext);
  const isDark = theme.dark;

  return (
    <img
      src={isDark ? darkLogo : lightLogo}
      className={className}
      style={{
        maxWidth: '100%',
        height: 'auto',
        objectFit: 'contain',
        ...style,
      }}
      alt="Hover Logo"
    />
  );
};

export default Logo;
