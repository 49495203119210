import React from 'react';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageWrap } from 'components/Containers';

import { loginSuccessful } from 'redux/actions/profile';
import { hoverTrustCoApi } from 'api';
import { triggerToast } from '../../redux/actions/ui';

class RedirectLogin extends React.Component {
  state = {
    loginComplete: false,
    loginError: false,
  };

  componentDidMount() {
    const handleLogin = async token => {
      await hoverTrustCoApi
        .authenticatedCall({ url: '/auth/csrfToken' })
        .then(res => res);

      this.props.loginSuccessful(token);

      this.setState({
        loginComplete: true,
      });
    };

    const { search } = window.location;

    if (search) {
      const code = qs.parse(search, { ignoreQueryPrefix: true });

      if (code.token) {
        handleLogin(code.token).catch(err => {
          this.props.triggerToast('Failed to login', 'error');
          this.setState({
            loginError: true,
          });
        });
      }
    }
  }

  render() {
    if (this.state.loginError) {
      return <Redirect to="/login" />;
    }
    return (
      <PageWrap>
        {this.state.loginComplete && <Redirect to="/account" />}
      </PageWrap>
    );
  }
}

export default connect(null, { loginSuccessful, triggerToast })(RedirectLogin);
