import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import s from './Modal.module.scss';
import { Box, Button, Heading, Modal, Paragraph } from 'components/Wrapped';
import Padlock from '../../components/Svgs/Padlock';
import { ThemeContext } from 'grommet';

const CompleteOnboardingModal = ({ t, showModal, setShowModal }) => {
  const theme = useContext(ThemeContext)
  return (
    <Modal show={showModal} toggleModal={setShowModal} width="large" pad="none">
      <Box pad="medium">
        <Box justify="start" pad={{ horizontal: 'small' }}>
          <Padlock width={'5rem'} fill={theme.global.colors.primary} />
        </Box>
        <Box justify="start">
          <Heading level={4}>{t('modals.onboarding.title')}</Heading>
          <Paragraph margin={{ top: 'small' }}>
            {t('modals.onboarding.subtitle')}
          </Paragraph>
        </Box>
        <Box direction="row" justify="start">
          <Link to="/account/onboarding">
            <Button color="primary" onClick={setShowModal}>
              {t('buttons.startNow')}
            </Button>
          </Link>
          <Button
            margin={{ horizontal: 'xsmall' }}
            color="primary"
            primary={false}
            onClick={setShowModal}
          >
            {t('buttons.remindMeLater')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default withNamespaces()(CompleteOnboardingModal);
