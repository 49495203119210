import React, { useContext, useState } from 'react';
import { Box, Button, Anchor, ThemeContext } from 'grommet';
import { Close } from 'grommet-icons';
import { Text } from 'components/Wrapped';
import { withNamespaces } from 'react-i18next';

const TradingNotice = ({ t, screenId}) => {
  const [visible, setVisible] = useState(() => {
    if (!screenId) return true
    return !(window.tradingNoticeClosed && window.tradingNoticeScreenId === screenId);

  });

  if (!visible) return null;

  const handleClose = () => {
    setVisible(false);
    window.tradingNoticeClosed = true;
    window.tradingNoticeScreenId = screenId;
  };

  const noticeTitle = t('tradingNotice.title');
  const noticeContent = t('tradingNotice.content');

  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const email = noticeContent.match(emailRegex)?.[0];
  const textParts = email ? noticeContent.split(email) : [noticeContent];
  const theme = useContext(ThemeContext);
  return (
    <Box
      background={theme.global.colors.trendingNoticeBackground}
      pad="medium"
      round="small"
      style={{ position: 'relative' }}
      elevation={theme.dark ? 'none' : 'small'}
    >
      {/* Close Button Positioned Absolutely */}
      <Button
        icon={<Close size="small" />}
        onClick={handleClose}
        plain
        style={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          padding: '4px',
          borderRadius: '50%',
          background: 'rgba(0, 0, 0, 0.1)',
        }}
      />

      {/* Title */}
      <Text
        size="medium"
        weight="bold"
        textAlign="center"
        margin={{ bottom: 'small' }}
      >
        {noticeTitle}
      </Text>

      {/* Content */}
      <Box align="center">
        <Text size="13px" textAlign="center">
          {textParts[0]}
          {email && (
            <Anchor href={`mailto:${email}`} style={{ fontWeight: 600 }}>
              {email}
            </Anchor>
          )}
          {textParts[1]}
        </Text>
      </Box>
    </Box>
  );
};

export default withNamespaces()(TradingNotice);
