import * as React from "react";
const CheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="m423.28-416.37-79.78-79.78q-12.43-12.44-31.35-12.44-18.91 0-31.35 12.44-12.43 12.43-12.31 31.35.12 18.91 12.55 31.34l110.18 110.18q13.76 13.67 32.11 13.67 18.34 0 32.02-13.67L677.76-545.7q12.44-12.43 12.44-31.22 0-18.8-12.44-31.23-12.43-12.44-31.35-12.44-18.91 0-31.34 12.44L423.28-416.37ZM480-71.87q-84.91 0-159.34-32.12-74.44-32.12-129.5-87.17-55.05-55.06-87.17-129.5Q71.87-395.09 71.87-480t32.12-159.34q32.12-74.44 87.17-129.5 55.06-55.05 129.5-87.17 74.43-32.12 159.34-32.12t159.34 32.12q74.44 32.12 129.5 87.17 55.05 55.06 87.17 129.5 32.12 74.43 32.12 159.34t-32.12 159.34q-32.12 74.44-87.17 129.5-55.06 55.05-129.5 87.17Q564.91-71.87 480-71.87Z" />
  </svg>
);
export default CheckedIcon;
