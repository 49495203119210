import React, { useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, ThemeContext } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import ExclamationMarkIcon from '../../../../../components/Svgs/ExclamationMarkIcon';

const PendingApproval = ({ t: translate }) => {
  const t = nestedTranslate(translate, 'account.onboarding.documents');
  const theme = useContext(ThemeContext);
  return (
    <>
      <Box
        margin={{ vertical: 'medium' }}
        pad="small"
        direction="row"
        align="center"
        border={{ color: 'brand', size: 'small' }}
        style={{ position: 'relative' }}
      >
        <Box>
          <ExclamationMarkIcon fill={theme.global.colors.primary} width="38px" height="38px" />
        </Box>
        <Box pad="medium">
          <WrappedParagraph level={4}>
            {t('notifications.pendingApproval')}
          </WrappedParagraph>
        </Box>
      </Box>
    </>
  );
};

export default withNamespaces()(PendingApproval);
