import * as React from 'react';

const ExclamationMarkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path
      d="M479.93-273.3q17.62 0 29.42-11.66 11.8-11.65 11.8-29.27 0-17.62-11.73-29.54-11.74-11.92-29.35-11.92-17.62 0-29.42 11.91t-11.8 29.53q0 17.61 11.73 29.28 11.74 11.67 29.35 11.67Zm2.19-161.71q16.05 0 26.9-10.94 10.86-10.94 10.86-26.94v-177.46q0-16-10.98-26.94-10.97-10.93-27.02-10.93-16.05 0-26.9 10.93-10.86 10.94-10.86 26.94v177.46q0 16 10.98 26.94 10.97 10.94 27.02 10.94ZM480.2-73.3q-84.44 0-158.48-31.96-74.03-31.96-129.27-87.19-55.23-55.24-87.19-129.3Q73.3-395.82 73.3-480.31q0-84.5 31.96-158.58 31.96-74.09 87.17-129t129.28-86.94q74.08-32.03 158.59-32.03t158.61 32.02q74.11 32.02 129 86.91 54.9 54.88 86.92 129.08 32.03 74.2 32.03 158.67 0 84.46-32.03 158.5-32.03 74.03-86.94 129.12t-129.08 87.17Q564.64-73.3 480.2-73.3Zm.13-75.76q138.05 0 234.33-96.51 96.28-96.52 96.28-234.76 0-138.05-96.16-234.33-96.15-96.28-234.86-96.28-137.79 0-234.33 96.16-96.53 96.15-96.53 234.86 0 137.79 96.51 234.33 96.52 96.53 234.76 96.53ZM480-480Z" />
  </svg>
);
export default ExclamationMarkIcon;
